<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocFaltantes"
      :enabledActions="false"
      :nameToExport="'Documentos Faltantes'"
    >
      <template v-slot:column>
        <DxColumn data-field="modelo" alignment="right" />
        <DxColumn data-field="serie" alignment="right" caption="Série"  />
        <DxColumn data-field="numero" alignment="right" caption="Número"  />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>